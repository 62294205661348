import { Icon, IconProps } from './Icon';
import { MoneyBillAlt } from '@styled-icons/fa-regular';

export function MoneyBillIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <MoneyBillAlt />
    </Icon>
  );
}
